import React, { useEffect, useState } from "react";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import JobPostStep1 from "../../../../components/JobPostComponents/JobPostStep1";

import "../JobOpportunity.css";
import JobPostStep2 from "../../../../components/JobPostComponents/JobPostStep2";
import IntershipStep2 from "../../../../components/JobPostComponents/IntershipStep2";
import { GeneralCaseCompetitionsStep2 } from "../../../../components/JobPostComponents/GeneralCaseCompetitionStep2";
import ApiHandler from "../../../../model/ApiHandler";
import { getUser, getUserToken } from "../../../../components/AppStorageKey";
import { useNavigate, useParams } from "react-router-dom";
import CustomAlertComp from "../../../../components/CustomAlertComp";
import CustomLoader from "../../../../components/CutomLoader";
import RightModalComponent from "../../../../components/RightModalComponent";

type FormData = {
  companyName: string;
  jobTitle: string;
  jobNature: string;
  workPlaceType: string;
  workLocation: string;
  jobFunction: string;
  experienceRequiredYear: string;
  Opportunitytype: any;
  opportunityTitle: string;
  description: any;
  startDate: any;
  logo: any;
  organizationName: any;
  categories: any;
  skills: any;
  numberOfApplicationsAllowed: any;
  collectResume: any;
  NoOfOpenings: any;
  endDate: any;
  salaryType: any;
  Variable: any;
  workingDays: any;
  maxExperience: any;
  minExperience: any;
  jobTiming: any;
  city: any;
  minSalary: any;
  maxSalary: any;
  stipend: any;
  subOpportunity: any;
  modeOfEvent: any;
  country: any;
  state: any;
  location: any;
  participationType: any;
  min_participation: any;
  max_participation: any;
  cityInput: any;
};

const initialJobPostValues = {
  companyName: "",
  jobTitle: "",
  jobNature: "",
  workPlaceType: "",
  workLocation: "",
  jobFunction: "",
  experienceRequiredYear: "",
  Opportunitytype: {
    label: "",
    value: "",
  },
  opportunityTitle: "",
  description: "",
  startDate: "",
  logo: "",
  organizationName: {
    label: "",
    value: "",
  },
  categories: "",
  skills: "",
  numberOfApplicationsAllowed: "",
  collectResume: "1",
  NoOfOpenings: "",
  endDate: "",
  salaryType: "",
  country: "",
  cityInput: "",
  state: "",
  minSalary: "",
  maxSalary: "",
  maxExperience: "",
  minExperience: "",
  Variable: "",
  workingDays: "",
  jobTiming: "",
  city: "",
  stipend: "",
  subOpportunity: "",
  modeOfEvent: "",
  location: "",
  participationType: "",
  min_participation: "",
  max_participation: "",
};

export const initialJobPostErrors = {
  companyName: "",
  jobTitle: "",
  jobNature: "",
  workPlaceType: "",
  workLocation: "",
  jobFunction: "",
  experienceRequiredYear: "",
  opportunityTitle: "",
  organizationName: "",
  categories: "",
  skills: "",
  description: "",
  numberOfApplicationsAllowed: "",
  NoOfOpenings: "",
  startDate: "",
  endDate: "",
  salaryType: "",
  minSalary: "",
  maxSalary: "",
  maxExperience: "",
  minExperience: "",
  Variable: "",
  workingDays: "",
  jobTiming: "",
  city: "",
  stipend: "",
  subOpportunity: "",
  modeOfEvent: "",
  country: "",
  state: "",
  location: "",
  participationType: "",
  min_participation: "",
  max_participation: "",
  cityInput: "",
};

const JobPostForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const apiHandler = new ApiHandler();

  const [formData, setFormData] = useState(initialJobPostValues);
  const [currentStep, setCurrentStep] = useState(1);
  const [errors, setErrors] = useState(initialJobPostErrors);
  const [organisationList, setOrganisationList] = useState([]);
  const [opportunityType, setOpportunityType] = useState([]);
  const [subOpportunityType, setSubOpportunityType] = useState([]);
  const [skills, setSkills] = useState([]);
  const [categories, setCategories] = useState([]);
  const [city, setCity] = useState([]);
  const [alert, setAlert] = useState<any>({
    msg: "",
    type: "",
    isActive: false,
  });
  const [loading, setLoading] = useState<any>(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [err, setErr] = useState<any>(false);
  const handleChange = (name: any, value: any) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const getOrganizationList = () => {
    apiHandler.getAllOrganizationList(
      (message: string, data: any) => {
        if (data?.organization_name.length !== 0) {
          const options = data?.organization_name?.map((item: any) => ({
            value: item.id,
            label: item.name,
          }));
          setOrganisationList(options);
          return;
        }
      },
      (message: any) => {
        console.log("Error fetching mentors:", message);
      }
    );
  };

  const getAllOpportunityType = () => {
    apiHandler.getOpportunityType(
      (message: string, data: any) => {
        if (data?.opportunity_type.length !== 0) {
          const options = data?.opportunity_type?.map((item: any) => ({
            value: item.id,
            label: item.name,
          }));
          setOpportunityType(options);
          const opportunityTypes = options.find(
            (type: any) => type.label === id
          );
          if (opportunityTypes) {
            setFormData((prevFormData) => ({
              ...prevFormData,
              Opportunitytype: opportunityTypes,
            }));
          }
          return;
        }
      },
      (message: any) => {
        console.log("Error fetching mentors:", message);
      }
    );
  };

  const getAllSubOpportunityType = () => {
    apiHandler.getSubOpportunityType(
      (message: string, data: any) => {
        if (data?.sub_opportunity_type.length !== 0) {
          const options = data?.sub_opportunity_type?.map((item: any) => ({
            value: item.id,
            label: item.name,
          }));
          setSubOpportunityType(options);
          return;
        }
      },
      (message: any) => {
        console.log("Error fetching mentors:", message);
      }
    );
  };

  const getAllSkillsList = () => {
    apiHandler.getSkillsList(
      (message: string, data: any) => {
        //  console.log(data , "ss")
        if (data?.skill.length !== 0) {
          const options = data?.skill?.map((item: any) => ({
            value: item.id,
            label: item.name,
          }));
          setSkills(options);
          return;
        }
      },
      (message: any) => {
        console.log("Error fetching mentors:", message);
      }
    );
  };

  const getAllCategories = () => {
    apiHandler.getCategories(
      formData.Opportunitytype.value,
      (message: string, data: any) => {
        if (data?.host_category.length !== 0) {
          const options = data?.host_category?.map((item: any) => ({
            value: item.id,
            label: item.name,
          }));
          setCategories(options);
          return;
        }
      },
      (message: any) => {
        console.log("Error fetching mentors:", message);
      }
    );
  };

  useEffect(() => {
    const fieldsToCheck: (keyof typeof errors)[] = [
      "startDate",
      "endDate",
      "maxSalary",
      "minSalary",
      "maxExperience",
      "minExperience",
      "max_participation",
      "min_participation",
    ];
    const fieldsValues = fieldsToCheck.map((field) => errors[field]);
    const allFieldsEmpty = fieldsValues.every((field) => field === "");
    setErr(allFieldsEmpty);
  }, [errors]);

  useEffect(() => {
    // Reset errors
    setErrors((prevErrors) => ({
      ...prevErrors,
      startDate: "",
      endDate: "",
      maxSalary: "",
      minSalary: "",
      maxExperience: "",
      minExperience: "",
      max_participation: "",
      min_participation: "",
    }));

    // Validate if end date is smaller than start date
    if (
      formData.startDate &&
      formData.endDate &&
      formData.endDate < formData.startDate
    ) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        endDate: "End date should be after the start date.",
      }));
    }
    if (
      formData.minSalary &&
      formData.maxSalary &&
      parseInt(formData.maxSalary) <= parseInt(formData.minSalary)
    ) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        maxSalary: "Max salary should be greater than min salary",
      }));
      //allErrorsResolved = false;
    }
    if (
      formData.minExperience &&
      formData.maxExperience &&
      parseInt(formData.maxExperience) <= parseInt(formData.minExperience)
    ) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        maxExperience: "Max experience should be greater than min experience",
      }));
      // allErrorsResolved = false;
    }
    if (
      formData.min_participation &&
      formData.max_participation &&
      parseInt(formData.max_participation) <=
        parseInt(formData.min_participation)
    ) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        max_participation: "Max  should be greater than min ",
      }));
      //allErrorsResolved = false;
    }
  }, [
    formData.startDate,
    formData.endDate,
    formData.minSalary,
    formData.maxSalary,
    formData.maxExperience,
    formData.minExperience,
    formData.min_participation,
    formData.max_participation,
  ]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const token = await getUserToken();
    setLoading(true);
    apiHandler.jobPostForm(
      formData,
      token,
      (message: string, data: any) => {
        setLoading(false);
        setAlert({
          message: message,
          type: "success",
          isActive: true,
        });
        if (id == "Jobs") {
          setTimeout(() => {
            navigate("/explore-job", { state: { jobDatas: "" } });
          }, 7000);
        } else if (id == "Internships") {
          setTimeout(() => {
            navigate("/interships");
          }, 7000);
        } else if (id == "General & Case Competitions") {
          setTimeout(() => {
            navigate("/General&CaseCompetitions");
          }, 7000);
        } else if (id == "Quizzes") {
          setTimeout(() => {
            navigate("/Quizzes");
          }, 7000);
        } else if (id == "Workshops & Webinar") {
          setTimeout(() => {
            navigate("/Webinars&Workshops");
          }, 7000);
        }
      },
      (message: any) => {
        console.log("Error fetching categories:", message);
        // for (const [key, value] of Object.entries(message.error)) {
        setAlert({
          message: message,
          type: "error",
          isActive: true,
        });
        // }
      }
    );
  };

  const handleStep = (e: any) => {
    e.preventDefault();
    const requiredFields: (keyof FormData)[] = [];
    let hasError = false;

    if (
      formData.Opportunitytype.value == "1" ||
      formData.Opportunitytype.value == "2"
    ) {
      requiredFields.push(
        "opportunityTitle",
        "organizationName",
        "categories",
        "skills",
        "description"
      );
    } else if (
      formData.Opportunitytype.value == "3" ||
      formData.Opportunitytype.value == "4" ||
      formData.Opportunitytype.value == "5"
    ) {
      requiredFields.push(
        "opportunityTitle",
        "organizationName",
        "categories",
        "skills",
        "description",
        "modeOfEvent"
      );
      if (formData.Opportunitytype.value == "3") {
        requiredFields.push("subOpportunity");
      }
      if (formData.modeOfEvent === "0") {
        requiredFields.push("country", "state", "cityInput", "location");
      }
    }
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [field]: `${
            field.charAt(0).toUpperCase() + field.slice(1)
          } is required.`,
        }));
        hasError = true;
      } else {
        // Check if the current field is categories and if it's not empty
        if (field == "categories" && formData[field].length > 0) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            [field]: "",
          }));
        }
        if (field == "skills" && formData[field].length > 0) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            [field]: "",
          }));
        }
      }
    });
    if (!hasError) {
      setCurrentStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleNext = (e: any, direction: string) => {
    switch (currentStep) {
      case 1:
        handleStep(e);
        break;
      case 2:
        if (direction === "back") {
          setCurrentStep(1);
        } else {
          handleSubmit(e);
        }
        break;
      case 3:
        break;
      // Add more cases if you have additional steps
      default:
        break;
    }
  };

  const getAllCity = () => {
    apiHandler.getCity(
      (message: string, data: any) => {
        // console.log(data , "yrtcity")
        if (data?.city.length !== 0) {
          const options = data?.city?.map((item: any) => ({
            value: item.city_id,
            label: item.city_name,
          }));
          setCity(options);
          return;
        }
      },
      (message: any) => {
        console.log("Error fetching mentors:", message);
      }
    );
  };

  const checkUserLogin = async () => {
    try {
      let user: any = await getUser();
      user = JSON.parse(user || "");
      //setActiveUser(user || ''); // H
      if (user && user.user_profile.organization) {
        const organisation = user.user_profile.organization;
        const { id, name } = organisation;
        const newOrg = {
          label: name,
          value: id,
        };

        setFormData((prevFormData) => ({
          ...prevFormData,
          organizationName: newOrg,
        }));
      }
    } catch (error) {
      console.error("Error checking user login:", error);
    }
  };

  useEffect(() => {
    checkUserLogin();
  }, []);

  useEffect(() => {
    setLoading(true);
    getOrganizationList();
    getAllOpportunityType();
    getAllSkillsList();
    getAllCategories();
    getAllCity();
    getAllSubOpportunityType();
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);
    return () => clearTimeout(timer);
  }, [formData.Opportunitytype.value]);

  const handlePopUP = (e: any) => {
    e.preventDefault();
    const requiredFields: (keyof FormData)[] = [];
    let hasError = false;

    if (formData.Opportunitytype.value == "1") {
      requiredFields.push(
        "startDate",
        "endDate",
        "salaryType",
        "workingDays",
        "NoOfOpenings",
        "maxExperience",
        "minExperience",
        "workPlaceType",
        "jobTiming"
      );
      if (formData.salaryType == "1") {
        requiredFields.push("minSalary", "maxSalary");
      } else if (formData.salaryType == "0") {
        requiredFields.push("maxSalary");
      }

      if (
        formData.workPlaceType == "in_office" ||
        formData.workPlaceType == "hybrid"
      ) {
        requiredFields.push("city");
        if (formData.city && formData.city.length > 0) {
          const cityIndex = requiredFields.indexOf("city");
          if (cityIndex !== -1) {
            requiredFields.splice(cityIndex, 1);
          }

          setErrors((prevErrors) => ({
            ...prevErrors,
            city: "", // Reset the error for the "city" field by setting it to an empty string
          }));
        }
      } else {
        // Remove "city" from requiredFields if it's no longer required
        const cityIndex = requiredFields.indexOf("city");
        if (cityIndex !== -1) {
          requiredFields.splice(cityIndex, 1);
        }
        // Reset the error for the "city" field
        setErrors((prevErrors) => ({
          ...prevErrors,
          city: "",
        }));
      }
    } else if (formData.Opportunitytype.value == "2") {
      requiredFields.push(
        "startDate",
        "endDate",
        "workingDays",
        "stipend",
        "NoOfOpenings",
        "workPlaceType",
        "jobTiming"
      );
      if (formData.stipend == "1") {
        requiredFields.push("salaryType", "Variable");
      } else if (formData.stipend == "0") {
        const index = requiredFields.indexOf("salaryType");
        if (index !== -1) {
          requiredFields.splice(index, 1);
        }
        // Reset the error for "salaryType"
        setErrors((prevErrors) => ({
          ...prevErrors,
          salaryType: "",
        }));
      }

      if (formData.salaryType == "1") {
        requiredFields.push("minSalary", "maxSalary");
      } else if (formData.salaryType == "0") {
        requiredFields.push("maxSalary");
      }

      if (
        formData.workPlaceType === "in_office" ||
        formData.workPlaceType === "hybrid"
      ) {
        requiredFields.push("city");
        if (formData.city && formData.city.length > 0) {
          const cityIndex = requiredFields.indexOf("city");
          if (cityIndex !== -1) {
            requiredFields.splice(cityIndex, 1);
          }

          setErrors((prevErrors) => ({
            ...prevErrors,
            city: "", // Reset the error for the "city" field by setting it to an empty string
          }));
        }
      } else {
        // Remove "city" from requiredFields if it's no longer required
        const cityIndex = requiredFields.indexOf("city");
        if (cityIndex !== -1) {
          requiredFields.splice(cityIndex, 1);
        }

        // Reset the error for the "city" field
        setErrors((prevErrors) => ({
          ...prevErrors,
          city: "",
        }));
      }
    } else if (
      formData.Opportunitytype.value == "3" ||
      formData.Opportunitytype.value == "4" ||
      formData.Opportunitytype.value == "5"
    ) {
      requiredFields.push("participationType", "startDate", "endDate");
      if (formData.participationType === "2") {
        requiredFields.push("min_participation", "max_participation");
      }
    }

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [field]: `${
            field.charAt(0).toUpperCase() + field.slice(1)
          } is required.`,
        }));
        hasError = true;
      }
    });

    if (!hasError && err === true) {
      setIsModalOpen(!isModalOpen);
    }
  };

  const handleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <>
      {loading && ( // Render loading indicator if data is loading
        <CustomLoader isLoading={loading} />
      )}
      <section className="careerForm_section pb-2">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-12 col-sm-12 border mx-auto rounded-4">
              <div className="form_start py-4  px-4">
                <h4>Post New {id}</h4>
                <hr />
                <form id="careerForm">
                  {currentStep === 1 && (
                    <div id="step1" className={`form-section`}>
                      <JobPostStep1
                        value={formData}
                        error={errors}
                        handleChange={handleChange}
                        opportunityTypes={formData.Opportunitytype.value}
                        organisationList={organisationList}
                        opportunityType={opportunityType}
                        setOrganisationList={setOrganisationList}
                        skills={skills}
                        categories={categories}
                        setFormData={setFormData}
                        subOpportunityType={subOpportunityType}
                        disabled={false}
                      />
                      <div className="text-center">
                        <button
                          className="btn"
                          type="button"
                          onClick={(e) => {
                            handleNext(e, "next");
                          }}
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  )}
                  {currentStep === 2 && (
                    <div id="step2" className={`form-section`}>
                      {formData.Opportunitytype.value == "1" && (
                        <JobPostStep2
                          value={formData}
                          handleChange={handleChange}
                          startDate={formData.startDate}
                          city={city}
                          setFormData={setFormData}
                          error={errors}
                          disabled={false}
                        />
                      )}
                      {formData.Opportunitytype.value == "2" && (
                        <IntershipStep2
                          value={formData}
                          handleChange={handleChange}
                          city={city}
                          startDate={formData.startDate}
                          error={errors}
                          setFormData={setFormData}
                          disabled={false}
                        />
                      )}
                      {(formData.Opportunitytype.value == "3" ||
                        formData.Opportunitytype.value == "4" ||
                        formData.Opportunitytype.value == "5") && (
                        <GeneralCaseCompetitionsStep2
                          value={formData}
                          handleChange={handleChange}
                          startDate={formData.startDate}
                          error={errors}
                          disabled={false}
                          noofapplication={false}
                          enddate={false}
                        />
                      )}

                      <div className="text-center mt-5">
                        <button
                          className="btn"
                          type="button"
                          onClick={(e) => {
                            handleNext(e, "back");
                          }}
                        >
                          Back
                        </button>
                        {/* <button className="btn" type="button" onClick={handleSubmit}>Submit</button> */}
                        <button
                          className="btn"
                          type="button"
                          onClick={(e: any) => handlePopUP(e)}
                        >
                          Submit
                        </button>
                      </div>

                      <RightModalComponent
                        title={"Confirm Details"}
                        show={isModalOpen}
                        handleModal={handleModal}
                        width={"60%"}
                      >
                        <div style={{ overflowY: "scroll", height: "90vh" }}>
                          <JobPostStep1
                            value={formData}
                            error={errors}
                            handleChange={handleChange}
                            opportunityTypes={formData.Opportunitytype.value}
                            organisationList={organisationList}
                            opportunityType={opportunityType}
                            setOrganisationList={setOrganisationList}
                            skills={skills}
                            categories={categories}
                            setFormData={setFormData}
                            subOpportunityType={subOpportunityType}
                            disabled={true}
                          />
                          {formData.Opportunitytype.value == "1" && (
                            <JobPostStep2
                              value={formData}
                              handleChange={handleChange}
                              startDate={formData.startDate}
                              city={city}
                              setFormData={setFormData}
                              error={errors}
                              disabled={true}
                              noofapplication={true}
                              enddate={true}
                            />
                          )}
                          {formData.Opportunitytype.value == "2" && (
                            <IntershipStep2
                              value={formData}
                              handleChange={handleChange}
                              city={city}
                              startDate={formData.startDate}
                              error={errors}
                              setFormData={setFormData}
                              disabled={true}
                              noofapplication={true}
                              enddate={true}
                            />
                          )}
                          {(formData.Opportunitytype.value == "3" ||
                            formData.Opportunitytype.value == "4" ||
                            formData.Opportunitytype.value == "5") && (
                            <GeneralCaseCompetitionsStep2
                              value={formData}
                              handleChange={handleChange}
                              startDate={formData.startDate}
                              error={errors}
                              disabled={true}
                              noofapplication={true}
                              enddate={true}
                            />
                          )}

                          <button
                            className="btn"
                            type="button"
                            onClick={handleSubmit}
                            style={{ whiteSpace: "nowrap", width: "170px" }}
                          >
                            Confirm & Submit
                          </button>
                        </div>
                      </RightModalComponent>
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>

        <CustomAlertComp alert={alert} />
      </section>
    </>
  );
};

export default JobPostForm;
