import React, { useState } from "react";
import CustomInputComp from "../CustomInputComp";
import {
  experienceRequiredYear,
  jobTiming,
  salaryType,
  workPlaceType,
  workingDays,
} from "../../utilities/app-const";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import "./../../styles/careersCss/jobApplyForm.css";
import Select from "react-select";

const JobPostStep2 = (props: any) => {
  const {
    error,
    value,
    handleChange,
    organisationList,
    handlePassout,
    formData,
    setFormData,
    startDate,
    city,
    disabled,
    enddate,
    noofapplication,
  } = props;

  const [selectedType, setSelectedType] = useState("");
  const [salary, setSalary] = useState("");

  const [collectResume, setCollectResume] = useState("");

  const currentYear = new Date().getFullYear();
  const passout = [{ title: "All", value: "All" }];

  for (let i = 0; i < 4; i++) {
    const year = currentYear - i;
    passout.push({ title: String(year), value: String(year) });
  }

  const handleWheel = (e: any) => {
    e.target.blur();
  };

  return (
    <div className="profile-Form mb-5">
      <h5 className="mb-4">Applications Details </h5>

      <CustomInputComp
        type={"date"}
        label="Application Start Date"
        id="startDate"
        name="startDate"
        value={value.startDate}
        required={true}
        error={error.startDate}
        handleChange={(e: any) => {
          handleChange("startDate", e.target.value);
        }}
        disabled={disabled}
      />

      <CustomInputComp
        type={"date"}
        label="Application End Date"
        id="endDate"
        name="endDate"
        value={value.endDate}
        min={value.startDate}
        required={true}
        error={error.endDate}
        handleChange={(e: any) => {
          handleChange("endDate", e.target.value);
        }}
        disabled={enddate}
      />

      <CustomInputComp
        type={"number"}
        label="Number of Applications allowed"
        id="numberOfApplicationsAllowed"
        name="numberOfApplicationsAllowed"
        value={value.numberOfApplicationsAllowed}
        handleChange={(e: any) => {
          handleChange("numberOfApplicationsAllowed", e.target.value);
        }}
        disabled={noofapplication}
        placeholder={"Leave blank for unlimited applicants"}
      />

      <div className="row align-items-center border py-2 mx-1 rounded-2 mb-4">
        <label className="col-auto mb-0">Collect Resume from Applicants</label>
        <div className="col">
          <div className="form-check form-switch float-end">
            <input
              className="form-check-input"
              type="checkbox"
              id="collectResumeToggle"
              checked={value.collectResume == "1"}
              value={value.collectResume}
              onChange={(e) => {
                const valueToSend = e.target.checked ? "1" : "0";
                // setCollectResume(valueToSend);
                handleChange("collectResume", valueToSend);
              }}
              disabled={disabled}
            />
          </div>
        </div>
      </div>

      <h5 className="mb-4">Salary Details</h5>
      <label className="form-label">
        Salary Type<span className="text-danger">*</span>
      </label>
      <div className="row form-group mb-4">
        {salaryType.map((salaryTypeOption, index) => (
          <div className="col" style={{ position: "relative" }}>
            <label
              style={{ background: disabled ? "#e9ecef" : "" }}
              key={index}
              htmlFor={`salaryType-${index}`}
              className={`form-label designation-box
    
                         ${
                           value.salaryType == salaryTypeOption.value
                             ? "active"
                             : ""
                         }`}
              onClick={() => {
                if (!disabled) {
                  handleChange("salaryType", salaryTypeOption.value);
                  setSalary(salaryTypeOption.value);
                }
              }}
            >
              <span className="checkbox_title">{salaryTypeOption.title}</span>
              {value.salaryType == salaryTypeOption.value && (
                <div className="selected-check">
                  <FontAwesomeIcon icon={faCircleCheck} />
                </div>
              )}
            </label>
          </div>
        ))}
        {error.salaryType && (
          <div className="text-danger">{error.salaryType}</div>
        )}
      </div>

      {value.salaryType == "1" && (
        <>
          <label className="form-label">Salary Range/Year</label>
          <div className="salary-input mb-2">
            <select
            //value={currency}
            // onChange={(e) => setCurrency(e.target.value)}
            >
              <option value="INR">INR</option>
              {/* Add more currency options here */}
            </select>
            <input
              type="number"
              placeholder="Min Salary"
              value={value.minSalary}
              onChange={(e) => handleChange("minSalary", e.target.value)}
              disabled={disabled}
              onWheel={handleWheel}
            />

            <input
              type="number"
              placeholder="Max Salary"
              value={value.maxSalary}
              onChange={(e) => handleChange("maxSalary", e.target.value)}
              disabled={disabled}
            />
          </div>
          {error.minSalary && (
            <div className="text-danger">{error.minSalary}</div>
          )}
          {error.maxSalary && (
            <div className="text-danger">{error.maxSalary}</div>
          )}
        </>
      )}

      {value.salaryType == "0" && (
        <>
          <label className="form-label">Salary Range/Year</label>
          <div className="salary-input mb-2">
            <select
            //value={currency}
            // onChange={(e) => setCurrency(e.target.value)}
            >
              <option value="INR">INR</option>
              {/* Add more currency options here */}
            </select>
            <input
              type="number"
              placeholder="Salary"
              value={value.maxSalary}
              onChange={(e) => handleChange("maxSalary", e.target.value)}
              disabled={disabled}
              onWheel={handleWheel}
            />
          </div>
          {error.maxSalary && (
            <div className="text-danger">{error.maxSalary}</div>
          )}
        </>
      )}

      <CustomInputComp
        type={"text"}
        label="Variable"
        id="Variable"
        name="Variable"
        required={false}
        value={value.Variable}
        handleChange={(e: any) => {
          handleChange("Variable", e.target.value);
        }}
        disabled={disabled}
      />

      <h5 className="mb-4">Job Details</h5>

      <label className="form-label">
        Experience<span className="red_star">*</span>
      </label>
      <div className="salary-input mb-2">
        <input
          type="number"
          placeholder="MinExperience"
          value={value.minExperience}
          onChange={(e) => handleChange("minExperience", e.target.value)}
          disabled={disabled}
          onWheel={handleWheel}
        />

        <input
          type="number"
          placeholder="MaxExperience"
          value={value.maxExperience}
          onChange={(e) => handleChange("maxExperience", e.target.value)}
          disabled={disabled}
          onWheel={handleWheel}
        />
      </div>

      {error.minExperience && (
        <div className="text-danger">{error.minExperience}</div>
      )}
      {error.maxExperience && (
        <div className="text-danger">{error.maxExperience}</div>
      )}

      <CustomInputComp
        type={"number"}
        label="No. of Openings"
        id="NoOfOpenings"
        name="NoOfOpenings"
        required={true}
        value={value.NoOfOpenings}
        handleChange={(e: any) => {
          handleChange("NoOfOpenings", e.target.value);
        }}
        error={error.NoOfOpenings}
        disabled={disabled}
      />

      <div className="form-group8 mb-4">
        <label htmlFor="your-name" className="form-label mb-2">
          Work Days<span className="red_star">*</span>{" "}
        </label>
        <div className="row form-group mb-4">
          {workingDays.map((workingDaysOption, index) => (
            <div className="col" style={{ position: "relative" }}>
              <label
                key={index}
                htmlFor={`workingDays-${index}`}
                style={{ background: disabled ? "#e9ecef" : "" }}
                className={`form-label gender-box  ${
                  value.workingDays === workingDaysOption.value ? "active" : ""
                }`}
                onClick={() => {
                  if (!disabled) {
                    handleChange("workingDays", workingDaysOption.value);
                  }
                }}
              >
                <span className="checkbox_title">
                  {workingDaysOption.title}
                </span>
                {value.workingDays &&
                  value.workingDays === workingDaysOption.value && (
                    <div className="selected-check">
                      <FontAwesomeIcon icon={faCircleCheck} />
                    </div>
                  )}
              </label>
            </div>
          ))}
          {error.workingDays && (
            <div className="text-danger">{error.workingDays}</div>
          )}
        </div>
      </div>

      <label className="form-label">
        WorkPlace Type <span className="red_star">*</span>
      </label>
      <div className="row form-group mb-4">
        {workPlaceType.map((workPlaceTypeOption, index) => (
          <div className="col" style={{ position: "relative" }}>
            <label
              style={{ background: disabled ? "#e9ecef" : "" }}
              key={index}
              htmlFor={`workPlaceType-${index}`}
              className={`form-label gender-box
                          
                         ${
                           value.workPlaceType === workPlaceTypeOption.value
                             ? "active"
                             : ""
                         }`}
              onClick={() => {
                if (!disabled) {
                  handleChange("workPlaceType", workPlaceTypeOption.value);
                  setSelectedType(workPlaceTypeOption.value);
                }
              }}
            >
              <span className="checkbox_title">
                {workPlaceTypeOption.title}
              </span>
              {value.workPlaceType === workPlaceTypeOption.value && (
                <div className="selected-check">
                  <FontAwesomeIcon icon={faCircleCheck} />
                </div>
              )}
            </label>
          </div>
        ))}
        {error.workPlaceType && (
          <div className="text-danger">{error.workPlaceType}</div>
        )}
      </div>

      <label className="form-label">
        Job Time<span className="red_star">*</span>
      </label>
      <div className="row form-group mb-4">
        {jobTiming.map((jobTimingOption, index) => (
          <div className="col" style={{ position: "relative" }}>
            <label
              style={{ background: disabled ? "#e9ecef" : "" }}
              key={index}
              htmlFor={`jobTiming-${index}`}
              className={`form-label gender-box
                          
                         ${
                           value.jobTiming === jobTimingOption.value
                             ? "active"
                             : ""
                         }`}
              onClick={() => {
                if (!disabled) {
                  handleChange("jobTiming", jobTimingOption.value);
                }
              }}
            >
              <span className="checkbox_title">{jobTimingOption.title}</span>
              {value.jobTiming === jobTimingOption.value && (
                <div className="selected-check">
                  <FontAwesomeIcon icon={faCircleCheck} />
                </div>
              )}
            </label>
          </div>
        ))}
        {error.jobTiming && (
          <div className="text-danger">{error.jobTiming}</div>
        )}
      </div>

      {(value.workPlaceType === "in_office" ||
        value.workPlaceType === "on_field" ||
        value.workPlaceType === "hybrid") && (
        <>
          <div className="form-group1 " style={{ marginBottom: "120px" }}>
            <label htmlFor="your-name" className="form-label">
              {" "}
              Work Location{" "}
            </label>
            {/* <p className="sub-label">Provide a list of yours (up to 10) , that mentees can use to discover you</p> */}
            <Select
              value={value.city}
              onChange={(selectedOption) =>
                setFormData((prevData: any) => ({
                  ...prevData,
                  city: selectedOption,
                }))
              }
              options={city}
              name="city"
              // className={`${errors.skills && 'is-invalid'}`}
              isMulti={true}
              isDisabled={disabled}
            />
            {error.city && <div className="text-danger">{error.city}</div>}
          </div>{" "}
        </>
      )}
      {value.workPlaceType == "wfh" && <></>}
    </div>
  );
};

export default JobPostStep2;
